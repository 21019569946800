<script>
// import FormVue from '@/modules/components/form/index.vue'
import cellPicker from '@/components/cell-picker'
import selectCar from '@/components/select-car'
import formSelectWrap from '@/components/common-selector/form-select-wrap'
import baseDataServices from '@/services/baseDataServices'
import { getWarehouseList } from '@/services/store'
import { arrayFormat } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  components:{ cellPicker,formSelectWrap },
  data() {
    const that = this
    const form = JSON.stringify({ 
      modelCode:'', 
      modelName:'', 
      seriesCode:'',
      seriesName: '' ,
      colourCode:'',
      interiorCode:'',
      options: [],
      dealerCode: ''
    })
    return {
      activeNames:['1','2'],
      formData:JSON.parse(form),   
      form,  
      outsideColor:[],
      innerColor: [],
      options:[],
      isEdit: true,
      list: [],
      showPicker: false,
      dealerList:[]
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
    getDealersId() {
      const { dealers } = this.userInfo || {}
      return dealers && dealers[0]?.id || ''
    },
    getCarName() {
      const { modelName='', seriesName= '' } = this.formData
      const arr = []
      seriesName && arr.push(seriesName)
      modelName && arr.push(modelName)
      return arr.join(' ')
    },
    getOptionsName() {
      const { options } = this.formData
      return (options.map(item=>item.dictName)).join(',')
    },
    getShowNextBtn() {
      const { dealerCode,seriesCode,modelCode,colourCode,interiorCode } = this.formData
      return dealerCode && seriesCode && modelCode && colourCode && interiorCode
    }
  },
  watch:{
    'formData.colourCode'(val){
      Object.assign(this.formData,{
        interiorCode:''
      })
      this.innerColor = []
      if (val){
        this.modelSalesInteriors()
      }
    },
    'formData.interiorCode'(val){
      Object.assign(this.formData,{
        options:[]
      })
      this.options = []
      if (val){
        this.modelSalesOptions()
      }
    },
    getDealersId(val){
      val && this.getDealerDetail()
    },    
  }, 
  methods:{
    reset() {
      this.formData=JSON.parse(this.form)
      this.innerColor= []
      this.options=[]
    },
    async confirm() {
      const { dealerCode,seriesCode,modelCode,colourCode,interiorCode } = this.formData
      if (!dealerCode) return this.$toast('请选择交付门店')
      if (!seriesCode || !modelCode) return this.$toast('请选择交付门店')
      if (!colourCode) return this.$toast('请选择外饰颜色')
      if (!interiorCode) return this.$toast('请选择内饰颜色')
      const res = await this.modelSalesMaterial()
      if (res){
        this.list = await this.getWarehouseList(res.materialCode)
        this.isEdit = false
      }
    },
    changeCar() {
      if (!this.isEdit) return
      // 选择意向车型
      const { modelCode='', modelName='', seriesCode='', seriesName= '' } = this.formData
      selectCar({ initCarInfo:{ modelCode, modelName, seriesCode, seriesName } })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            // this.$toast('请选择正确的车系车型')
            return
          }
          Object.assign(this.formData,carInfo)
          this.outsideColor = []
          this.modelSalesColours()
        })
        .catch(() => { })
    },
    optionsCancel() {
      this.showPicker = false
    },
    optionsConfirm(params) {
      console.log(params, 1111)
      this.formData.options = params
      this.optionsCancel()
    },
    // 物料
    modelSalesMaterial() {
      const { seriesCode,modelCode,colourCode,interiorCode,options } = this.formData
      const optionCodes = options.map(item=>item.dictCode)
      return baseDataServices.modelSalesMaterial({
        seriesCode,modelCode,colourCode,interiorCode,
        optionCodes
      })
    },
    // 查询库存
    getWarehouseList(materialCode) {    
      return getWarehouseList({
        materialCode,
        deliveryStoreCode:this.formData.dealerCode,
        roleType: 0
      })      
    },
    // 交付门店
    getDealerDetail(){
      baseDataServices.getDealerDetail({ id: this.getDealersId }).then(res=>{
        this.dealerList = res?.relations || []
      })
    },
    // 外饰
    modelSalesColours(params={}) {
      const { seriesCode,modelCode } = this.formData
      baseDataServices.modelSalesColours({ seriesCode,modelCode }).then(res=>{
        this.outsideColor=res
      })
    },
    // 内饰
    modelSalesInteriors() {
      const { seriesCode,modelCode,colourCode } = this.formData
      baseDataServices.modelSalesInteriors({ seriesCode,modelCode,colourCode }).then(res=>{
        this.innerColor=res
      })
    },    
    // 选装
    modelSalesOptions() {
      const { seriesCode,modelCode,colourCode,interiorCode } = this.formData
      baseDataServices.modelSalesOptions({ seriesCode,modelCode,colourCode,interiorCode }).then(res=>{
        this.options= arrayFormat(res,{
          name:'optionsName',value:'optionsCode',nameKey:'dictName',valueKey:'dictCode'
        })
      })
    }
  }
}
</script>
<template>
  <div class="page" :class="{read: !isEdit}">
    <div class="card">
      <div class="card-title">选择车辆配置</div>
      <van-field
        class="bus-field"
        label="车系车型"
        placeholder="请选择"
        right-icon="arrow"
        required
        :value="getCarName"
        readonly
        input-align="right"
        @click="changeCar"
        />
      <cellPicker v-model="formData.colourCode" class="bus-cell" :required="true" :isEdit="isEdit" title="外饰颜色" :columns="outsideColor"/>
      <cellPicker v-model="formData.interiorCode" class="bus-cell" :required="true" :isEdit="isEdit" title="内饰颜色" :columns="innerColor"/>
      <van-field
        class="bus-field"
        label="选装"
        placeholder="请选择"
        right-icon="arrow"
        :value="getOptionsName"
        readonly
        input-align="right"
        @click=" ()=>{ if(isEdit) showPicker = true}"
        />
    </div>
    <div class="card">
      <div class="card-title">选择交付门店</div>
      <cellPicker v-model="formData.dealerCode" class="bus-cell" :required="true" :isEdit="isEdit" labelKey="dealerName" valueKey="dealerCode" title="交付门店" :columns="dealerList"/>
    </div>
    <div v-if="!isEdit" class="card">
      <div class="card-title">车辆库存所在仓库</div>
      <div v-if="list.length" class="shop-list">
        <div v-for="(item,index) in list" :key="index" class="li">
          <div><img class="icon" width="14px" src="@/images/shop.png" /></div>
          <div class="van-ellipsis">{{item.warehouseName}}</div>
        </div>
      </div>
      <div v-else class="no-stock">
        <img class="icon" src="@/images/no_stock.png" />
        <div>无车辆库存</div>
      </div>
    </div> 
    <div v class="actions">
      <template v-if="isEdit">
        <button class="btn-common plain" @click="reset">重置</button>
        <button class="btn-common" :class="{hidden: !getShowNextBtn}" @click="confirm">查询库存</button>
      </template>
      <button v-else class="btn-common" @click="isEdit = true">继续查询</button>
    </div>
    <form-select-wrap
      :showPicker="showPicker"
      :cell-list="options"
      :multiple="true"
      @cancel="optionsCancel"
      @confirm="optionsConfirm"
    />
  </div>
</template>
<style lang="less" scoped>
.page{
  padding: 16px 16px 60px;
  &.read{
    /deep/ .van-field__right-icon, /deep/ .van-cell__right-icon{
      display: none;
    }
   .bus-cell /deep/ .value{
      padding: 0;
    }
  }
  .card{
    background: #fff;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    .card-title{
      padding: 12px;
      margin: 0 16px;
      position: relative;
      border-bottom: 1px solid #F3F3F4;
      font-size: 16px;
      &::before{
        content: "";
        position: absolute;
        top:50%;
        left: 0;
        transform: translateY(-50%);
        width: 2px;
        height: 12px;
        background: @yellow-text;
      }
    }
  }
  .shop-list{
    font-size: 14px;
    padding:0 16px;
    .li{
      display: flex;
      padding: 12px 0;
      justify-content: space-between;
      :not(:last-child){
        border-bottom: 1px solid #F3F3F4;
      }
    }
  }
  .no-stock{
    color: #D1D3D4;
    text-align: center;
    padding: 16px;
    font-size: 14px;
  }
  .actions{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 500px;
    display: flex;
    font-size: 16px;
    background: #fff;
    padding: 12px 0;
    .btn-common{
      flex: 1;
      margin: 0 10px;
    }
    .hidden{
      opacity: .5;
    }
  }
}
.bus-cell{
  /deep/ .red{
    color:#c8c9cc !important;
    font-size: 12px;
    &.value{
      padding-right: 4px;
    }
  }
}
.bus-field{
  /deep/ .van-field__label{
    color: #0D171A;
  }
}
</style>
